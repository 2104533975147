import Alert from "@material-ui/lab/Alert";
import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Alert severity="error" style={{ margin: 10 }}>
          {this.props.defaultMessage
            ? this.props.defaultMessage
            : "Something went wrong"}
        </Alert>
      );
    }
    return this.props.children;
  }
}
