import React from "react";
import { useParams } from "react-router-dom";
import DefaultPage from "../components/pageComponents/default";
import "tailwindcss/tailwind.css";
import "@material-tailwind/react/tailwind.css";
import { database } from "../firebase";
import { CircularProgress } from "@material-ui/core";
import { setPageInfo } from "../util/helper";
function Home() {
  const { type } = useParams();
  const [fileTypes, setfileTypes] = React.useState(null);
  //get fileType list
  React.useEffect(() => {
    database
      .child("apps")
      .orderByChild("index")
      .once("value")
      .then(function (dataSnapshot) {
        setfileTypes(dataSnapshot.val());
        let index = dataSnapshot.val().findIndex((item) => item.path === type);
        if (index !== -1)
          setPageInfo(
            dataSnapshot.val()[index].name,
            dataSnapshot.val()[index].description
          );
      });
  }, []);
  //console.log(fileTypes);
  return (
    <>
      {fileTypes ? (
        <DefaultPage fileTypes={fileTypes} type={type ?? "docs"} />
      ) : (
        <CircularProgress className="default-spinner" />
      )}
    </>
  );
}

export default Home;
