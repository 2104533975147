import React from "react";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import { logout } from "../server/auth";
import { userImage, userName } from "../App";
import { Tooltip } from "@material-ui/core";
function Header({ fileType, setShowTabs }) {
  return (
    <React.Fragment>
      <header className="sticky top-0 z-50 flex items-center px-4 h-16  shadow-md bg-white">
        {setShowTabs && (
          <Button
            color="gray"
            ripple="dark"
            buttonType="outline"
            rounded={true}
            onClick={setShowTabs}
            iconOnly={true}
            className="h-10 w-10 border-0 mr-2 menu-icon"
          >
            <Icon color="black" name="menu" size="2xl" />
          </Button>
        )}

        <img alt="app" src={fileType?.image} width="40" height="40" />
        {userName && (
          <>
            <p className="ml-2 text-gray-700 text-2xl">{fileType?.name}</p>
            <div
              className="flex md:mx-20 flex-grow items-center px-5 py-2
       bg-gray-100 text-gray-600 rounded-lg mx-5 focus-within:text-gray-600 focus-within:shadow-md search-section"
            >
              <Icon name="search" size="2xl" color="black" />
              <input
                type="text"
                placeholder="Search"
                className="flex-grow px-5 text-base bg-transparent outline-none w-3/4"
              />
            </div>

            <Tooltip title={`${userName} (Logout)`}>
              <div onClick={logout}>
                <img
                  name="apps"
                  loading="lazy"
                  className="cursor-pointer h-8 w-8 rounded-full ml-2"
                  src={userImage}
                  size="2xl"
                  alt={userName}
                />
              </div>
            </Tooltip>
          </>
        )}
      </header>
    </React.Fragment>
  );
}

export default Header;
