import React from "react";
import Model from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import apis from "../files/apis";
import Button from "@material-tailwind/react/Button";
import { useNavigate } from "react-router-dom";
import { openChildUrl } from "../util/helper";

export default function CreateNew({
  user,
  dbPath,
  urlPath,
  showModel,
  setShowModel,
}) {
  const [input, setInput] = React.useState("");
  const router = useNavigate();
  const createDocument = () => {
    if (!input) return;
    apis.createDocs(user, input, dbPath).then((doc) => {
      if (doc && doc.id) {
        if (urlPath) openChildUrl(urlPath, doc.id); //router(`/${urlPath}/${doc.id}`);
      }
    });
    setInput("");
    setShowModel(false);
  };
  return (
    <Model size="sm" active={showModel} toggler={() => setShowModel(false)}>
      <ModalBody>
        <input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          type="text"
          className="outline-none w-full"
          placeholder="Enter name of document"
          onKeyDown={(e) => e.key === "Enter" && createDocument()}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="blue"
          buttonType="link"
          onClick={(e) => setShowModel(false)}
          ripple="dark"
        >
          Cancel
        </Button>
        <Button color="blue" onClick={createDocument} ripple="light">
          Create
        </Button>
      </ModalFooter>
    </Model>
  );
}
