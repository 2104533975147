import { Paper } from "@material-ui/core";
import React from "react";
import { openChildUrl } from "../../util/helper";
import "./AllApps.css";
let appName = "allApps-";

export default function AllAppsList({ fileTypes }) {
  return (
    <div className={`${appName}parent`}>
      <h1>All Apps</h1>
      <div className={`${appName}container`}>
        {fileTypes &&
          fileTypes.map((item, index) => (
            <TypeView
              key={index}
              name={item.name}
              description={item.description}
              icon={item.image}
              path={item.path}
              dbPath={item.dbPath}
            />
          ))}
      </div>
      <br />
    </div>
  );
}
const TypeView = ({ name, description, icon, path, dbPath }) => {
  let url = openChildUrl(!dbPath ? path : null, !dbPath ? null : path, false);

  return (
    <Paper
      variant="outlined"
      className={`${appName}item`}
      // onClick={handleClick}
    >
      <a
        href={dbPath ? "/" + dbPath : url}
        target={dbPath ? "_self" : "_blank"}
        rel="noreferrer"
      >
        <img src={icon} alt={name} />
        <div className={`${appName}detail`}>
          <div className={`${appName}name`}>{name}</div>
          <div className={`${appName}description`}>{description}</div>
        </div>
      </a>
    </Paper>
  );
};
