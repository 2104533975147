import { v4 as uuid } from "uuid";

export const prepareSheetData = (oldState, newState) => {
  //console.log("util old state", oldState, newState);
  var arr = [];

  if (oldState.length === 1 && oldState[0].name !== newState.name) {
    arr.push(oldState[0]);
    arr.push(newState);
  } else if (oldState.length !== 1) arr.push(newState);
  else
    oldState.forEach((item) => {
      if (newState.name !== item.name) arr.push(newState);
      else arr.push(item);
    });
  //console.log("prepared sheet data", arr);
  return arr;
};

export const generateUniqueId = () => {
  const unique_id = uuid();
  return unique_id;
  // const chars =
  //   "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  // let autoId = "";
  // while (autoId.length < 20) {
  //   const bytes = randomBytes(40);
  //   bytes.forEach((b) => {
  //     // Length of `chars` is 62. We only take bytes between 0 and 62*4-1
  //     // (both inclusive). The value is then evenly mapped to indices of `char`
  //     // via a modulo operation.
  //     const maxValue = 62 * 4 - 1;
  //     if (autoId.length < 20 && b <= maxValue) {
  //       autoId += chars.charAt(b % 62);
  //     }
  //   });
  // }
  // return autoId;
};

export const isNumber = (text, getMessage) => {
  text += "";
  let test = /^\d+$/.test(text);
  if (getMessage) return test ? null : "Only number is allowed";
  return test;
};

export const findObj = (value, arrJson, key) => {
  if (!value || !key || !arrJson) return -1;
  for (let i = 0; i < arrJson.length; i++) {
    const element = arrJson[i][key];
    if (element === value) return i;
  }
  return -1;
};
export const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;
  //console.log("to shuffled ", array);

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  //console.log("shuffled array", array);
  return array;
};
export const checkIfRequired = (data, questions) => {
  let json = {};
  questions.forEach((ques) => {
    if (!data[ques.id] && ques.required) json[ques.id] = "Cannot be empty";
  });
  if (Object.keys(json).length === 0) return null;
  else return json;
};
export const validations = (validationList, value) => {
  try {
    if (value)
      for (let index = 0; index < validationList.length; index++) {
        const validationType = validationList[index];
        const validationMethod = getValidationMethod(validationType);
        //console.log(validationType, "value", value);

        try {
          const response = validationMethod(value, true);
          if (response) return response;
        } catch (error) {}
      }
  } catch (error) {}
  return false;
};

export const getValidationMethod = (type) => {
  switch (type) {
    case "number":
      return isNumber;
    case "email":
      return isEmail;
    case "phone":
      return isPhone;
    case "mobile":
      return isMobile;
    case "between":
      return isNumberBetween;
    case "range":
      return isDateRange;

    default:
      return;
  }
};

export const isEmail = (value) => {
  let test = /\S+@\S+\.\S+/.test(value);
  // if (getMessage)
  return test ? null : "Invalid email id";
};

export const isPhone = (value) => {
  let test = isNumber(value) && value.length === 6;

  // if (getMessage)
  return test ? null : "Phone number should be of length 6";
};

export const isMobile = (value) => {
  let test = isNumber(value) && value.length === 10;
  // if (getMessage)
  return test ? null : "Mobile number should be of length 10";
};

export const isNumberBetween = (value) => {
  let test = isNumber(value);
  // if (getMessage)
  return test ? null : "Number should be between";
};
export const isDateRange = (value) => {
  let test = true;
  // if (getMessage)
  return test ? null : "Date should be between";
};

export function groupBy(arr) {
  return arr.reduce((r, c) => ((r[c] = (r[c] || 0) + 1), r), {});
}

export const deleteArrayElement = (array, index) => {
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};

export const groupByJson = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
