import { EXTENSION, HTTP_SCHEME, PARENT_DOMAIN } from "./constants";

export const setUserGlobalInfo = ({ uid, email, image, name }) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("uid", uid);
    localStorage.setItem("userName", name);

    localStorage.setItem("userImage", image);
    localStorage.setItem("userEmail", email);
  }
};
export const getStorageUrl = (folderName, fileName) => {
  return `https://firebasestorage.googleapis.com/v0/b/meta-doc.appspot.com/o/${folderName}%2F${fileName}?alt=media&token=6aaf9fdc-f85a-4f50-9681-daf1b1b84079`;
};
export const fileTypeName = (name) => {
  //console.log("file type", name);
  if (name.includes("sheet") || name.includes("excel")) return "xls";
  else if (name.includes("document")) return "doc";
  else if (name.includes("javascript")) return "js";
  else if (name.includes("x-msdownload")) return "exe";
  else if (name.includes("x-zip-compressed")) return "zip";
  else if (name.includes("plain")) return "txt";
  else if (name.includes("x-gzip")) return "tar";
  else if (name.includes("svg+xml")) return "svg";
  return name;
};
export const isNumber = (text, getMessage) => {
  text += "";
  let test = /^\d+$/.test(text);
  if (getMessage) return test ? null : "Only number is allowed";
  return test;
};
export const deleteArrayElement = (array, index) => {
  if (index > -1) {
    array.splice(index, 1);
  }
  return array;
};
export const findObj = (value, arrJson, key) => {
  if (!value || !key || !arrJson) return -1;
  for (let i = 0; i < arrJson.length; i++) {
    const element = arrJson[i][key];
    if (element === value) return i;
  }
  return -1;
};

export const screenSizes = {
  lg: "(min-width: 992px)",
  xs: "(max-width: 768px)",
  md: "(min-width: 768px)",
  sm: "(max-width: 576px)",
  xl: "(min-width: 1200px)",
};
export const sanitizeString = (string) => {
  //console.log(string);
  // ".", "#", "$", "[", or "]"
  return string
    .replace(".", "-")
    .replace("#", "-")
    .replace("$", "-")
    .replace("[", "-")
    .replace("]", "-");
};
export const openChildUrl = (path, id, isOpen = true) => {
  let url =
    HTTP_SCHEME +
    (path ? path + "." : "") +
    `${PARENT_DOMAIN}${EXTENSION}${id ? "/" + id : "/"}`;
  if (isOpen) window.open(url, "_blank").focus();
  else return url.trim();
};
export const setPageInfo = (name, description) => {
  try {
    document.title = name;
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description);
  } catch (error) {}
};
