import React, { useState } from "react";
import { getSession } from "../../server/auth";
import Header from "../Header";
import UserTab from "../UserTab";

export default function CommonHome({
  setFileType,
  fileType,
  fileTypes,
  setShowAllApps,
  user,
}) {
  const [showTabs, setShowTabs] = React.useState(false);
  // const [user, setUser] = useState(null);
  // if (!user) getSession(setUser, true);
  const handleSideTab = () => {
    setShowTabs(!showTabs);
  };
  return (
    <>
      {user ? (
        <React.Fragment>
          <Header fileType={fileType} setShowTabs={handleSideTab} />
          <UserTab
            showTabs={showTabs}
            setShowTabsFn={setShowTabs}
            setFileType={setFileType}
            fileTypes={fileTypes}
            setShowAllApps={setShowAllApps}
            fileType={fileType?.name}
            userCreds={{ name: user.name, image: user.image }}
          />
        </React.Fragment>
      ) : (
        <div>Loading</div>
      )}
    </>
  );
}
