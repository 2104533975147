import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import withWidth from "@material-ui/core/withWidth";
// import { fileTypes } from "../files/Lists";
import { findObj } from "../files/util";
import { useNavigate } from "react-router-dom";
import { login } from "../server/auth";
import { openChildUrl, setPageInfo } from "../util/helper";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    zIndex: "auto",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function UseTab(props) {
  const router = useNavigate();
  const { fileTypes, setShowAllApps } = props;
  const [fileTypeToShow, setFileTypeToShow] = React.useState([]);
  React.useEffect(() => {
    let temp = fileTypes.filter((item) => item.index);
    setFileTypeToShow(temp);
    //console.log(" fileTypeToShow ", fileTypeToShow);
  }, []);
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.showTabs);
  const [selected, setselected] = React.useState(
    props.fileType ? findObj(props.fileType, fileTypes, "name") : 0
  );
  const handleDrawerOpen = () => {
    if (props.width !== "xs") setOpen(true);
  };
  React.useEffect(() => {
    if (props.showTabs) {
      handleDrawerOpen();
      props.setShowTabsFn(false);
    }
    //console.log("showtabs", props.showTabs);
  }, [props.showTabs]);
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handlePageClick = (index) => {
    if (index === fileTypeToShow.length) {
      setShowAllApps();
      setselected(index);
    } else if (!fileTypeToShow[index].dbPath) {
      openChildUrl(fileTypeToShow[index].path, null, true);
    } else {
      setselected(index);
      props.setFileType(fileTypeToShow[index]);
      router(`/${fileTypeToShow[index].path}`);
      if (!props.userCreds.name) {
        login();
      }
    }
    try {
      setPageInfo(
        fileTypeToShow[index].name,
        fileTypeToShow[index].description
      );
    } catch (error) {}
  };

  const attr = {
    height: "33",
    width: "33",
  };
  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <ListItem style={{ marginTop: 15 }}>
          <ListItemIcon>
            {props.userCreds.image ? (
              <img
                name="user"
                loading="lazy"
                className="cursor-pointer h-8 w-8 rounded-full ml-2"
                src={props.userCreds.image}
                size="2xl"
                alt="User"
              />
            ) : (
              <img
                className="cursor-pointer h-8 w-8 rounded-full ml-2"
                style={{ width: "2rem" }}
                alt=""
              />
            )}
          </ListItemIcon>
          {open && (
            <ListItemText
              primary={props.userCreds.name}
              style={{ color: "rgb(236 158 153)" }}
            />
          )}
        </ListItem>
        <Divider />

        <List>
          {fileTypeToShow.map((item, index) => (
            <ListItem
              button
              key={index}
              index={index}
              onClick={() => {
                if (selected === fileTypeToShow.length) setShowAllApps();
                handlePageClick(index);
              }}
              selected={selected === index}
            >
              <ListItemIcon>
                <img
                  width={attr.width}
                  height={attr.height}
                  src={item.image}
                  className="tab-icon"
                  alt={item.name}
                />
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
          <ListItem
            button
            onClick={() => handlePageClick(fileTypeToShow.length)}
            selected={selected === fileTypeToShow.length}
          >
            <ListItemIcon>
              <img
                width={attr.width}
                height={attr.height}
                src={"/moreapps.svg"}
                className="tab-icon"
                alt={"Apps"}
              />
            </ListItemIcon>
            <ListItemText primary={"All Apps"} />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
export default withWidth()(UseTab);
