import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";

import React, { useState } from "react";
import useFirestore from "../../hooks/useFirestore";
import DocumentRow from "../DocumentRow";
// import { fileTypes } from "../../files/Lists";
import CreateNew from "../CreateNew";
import { findObj } from "../../files/util";
import CommonHome from "../common/CommonHome";
import { getSession } from "../../server/auth";
import { uid, userEmail } from "../../App";
import AllAppsList from "../AllApps/AllAppsList";
export default function DefaultPage({ type, fileTypes }) {
  const [showModel, setShowModel] = React.useState(false);
  const [fileType, setFileType] = React.useState(
    type ? fileTypes[findObj(type, fileTypes, "dbPath")] : fileTypes[0]
  );
  const [showAllApps, setShowAllApps] = useState(fileType === "apps");
  const [user, setUser] = useState(null);
  const handleShowAllApps = () => {
    setShowAllApps(!showAllApps);
  };
  if (!user) getSession(setUser, false);
  else {
    if (user && !user.uid) {
      if (!showAllApps) {
        setShowAllApps(true);
      }
    }
  }
  const { docs } = useFirestore(fileType?.dbPath);
  // console.log("doc found for type", fileType, docs);

  return (
    <React.Fragment>
      {user ? (
        <React.Fragment>
          <div>
            <CommonHome
              fileTypes={fileTypes}
              setFileType={setFileType}
              fileType={fileType}
              user={user}
              setShowAllApps={handleShowAllApps}
            />
            <CreateNew
              dbPath={fileType?.dbPath}
              urlPath={fileType?.path}
              setShowModel={setShowModel}
              showModel={showModel}
              user={userEmail}
            />
            {showAllApps ? (
              <AllAppsList fileTypes={fileTypes} />
            ) : (
              <>
                <section
                  className="bg-[#F8F9FA] pb-10 px-10 add-file-section"
                  style={{ background: "#F8F9FA" }}
                >
                  <div className="max-w-3xl mx-auto">
                    <div className="flex items-center justify-between py-6">
                      <h2 className="text-gray-700 text-lg">
                        Start a new {fileType?.path}
                      </h2>
                      <Button
                        color="gray"
                        ripple="dark"
                        buttonType="outline"
                        rounded={true}
                        iconOnly={true}
                        className="border-0"
                      >
                        <Icon name="more_vert" color="gray" size="3xl" />
                      </Button>
                    </div>
                    <div>
                      <div
                        onClick={(e) => setShowModel(true)}
                        className="relative h-52 w-40 border-2 cursor-pointer add-hover"
                        style={{ borderWidth: 2 }}
                      >
                        <img
                          src="https://ssl.gstatic.com/docs/templates/thumbnails/docs-blank-googlecolors.png"
                          layout="fill"
                          alt="Add New"
                        />
                      </div>
                      <p className="mt-2 ml-2 font-semibold text-sm text-gray-700">
                        Blank
                      </p>
                    </div>
                  </div>
                </section>
                <section className="bg-white px-10 md:px-0 file-list-section">
                  <div className="max-w-3xl mx-auto py-8 text-sm text-gray-700">
                    <div className="flex items-center justify-between pb-5">
                      <h2 className="font-medium flex-grow">
                        My {fileType?.name}
                      </h2>
                      <p className="mr-12">Date Created</p>
                      {/* <Icon name="folder" color="gray" size="3xl" /> */}
                    </div>

                    {docs &&
                      docs.map((document) => (
                        <DocumentRow
                          key={document.id}
                          fileType={fileType}
                          id={document.id}
                          fileName={document.fileName ?? document.name}
                          date={document.timeStamp}
                        />
                      ))}
                  </div>
                </section>
              </>
            )}
          </div>
        </React.Fragment>
      ) : (
        <div>Loading</div>
      )}
    </React.Fragment>
  );
}
