import dayjs from "dayjs";
import React from "react";
import { openChildUrl } from "../util/helper";
import ErrorBoundary from "./common/ErrorBoundary";
export default function DocumentRow({ fileName, id, date, fileType }) {
  const handleClick = () => {
    // router(`/${fileType.path}/${id}`);
    openChildUrl(fileType.path, id);
  };
  const getDate = () => {
    try {
      return date?.toDate().toLocaleDateString();
    } catch (error) {
      if (date && typeof date == "object") {
        if (date.seconds && date.nanoseconds) {
          let val = parseInt(
            date.seconds + "" + (date.nanoseconds + "").substring(0, 3)
          );
          let val2 = dayjs(val).format("MM/DD/YYYY");
          return val2;
        }
      }
      // console.log(fileName, id, date, fileType);
      return "Error";
    }
  };
  // console.log(date);
  return (
    <div
      className="flex items-center p-4 rounded-lg
     hover:bg-gray-100 text-gray-700 text-sm cursor-pointer"
      onClick={handleClick}
    >
      <img alt={fileType.name} src={fileType.image} width={30} height={30} />
      <p className="flex-grow pl-5 w-10 pr-10 truncate">{fileName}</p>
      <ErrorBoundary>
        <p className="pr-5 text-sm">{getDate()}</p>
      </ErrorBoundary>
      {/* <Button
        color="gray"
        buttonType="outline"
        rounded={true}
        ripple="dark"
        iconOnly={true}
        className="border-0"
      >
        <Icon name="more_vert" size="3xl" />
      </Button> */}
    </div>
  );
}
